import React, { useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  or,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../Firebase";
import Header from "../components/Header";
import AddExpense from "../components/AddExpense";
import AddGroup from "../components/AddGroup";
import AddCategory from "../components/AddCategory";
import { ReactComponent as Edit } from "../assets/edit.svg";
import useAuth from "../config/hooks/useAuth";
import AddPaymentMethod from "../components/AddPaymentMethod";

const Home = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [expenses, setExpenses] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [editExpense, setEditExpense] = useState(null);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [group, setGroup] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const editExpenseModal = useRef();
  const closeModalRef = useRef();
  const { user } = useAuth();

  const getGroups = async () => {
    try {
      const q = query(collection(db, "groups"), where("user", "==", user.uid));
      const res = await getDocs(q);
      const data = res.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setGroups(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getCategories = async () => {
    try {
      const q = query(
        collection(db, "category"),
        or(where("user", "==", ""), where("user", "==", user.uid))
      );
      const res = await getDocs(q);
      const data = res.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const q = query(
        collection(db, "paymentMethod"),
        where("user", "==", user.uid)
      );
      const res = await getDocs(q);
      const data = res.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setPaymentMethods(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getExpenses = async () => {
    try {
      const q = query(
        collection(db, "expenses"),
        where("user", "==", user.uid),
        where("group", "==", selectedGroup.id),
        orderBy("date", "desc")
      );
      const res = await getDocs(q);
      let total = 0;
      const data = res.docs.map((doc) => {
        total = total + parseInt(doc.data().amount);
        return {
          id: doc.id,
          title: doc.data().title,
          amount: doc.data().amount,
          date: doc.data().date,
          group: doc.data().group,
        };
      });

      setTotalExpense(total);
      setExpenses(data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateExpense = async (id) => {
    try {
      await updateDoc(doc(db, "expenses", id), {
        title: title,
        amount: parseInt(amount),
        group: group,
        category: selectedCategory,
        paymentMethod: selectedPaymentMethod,
      });
      closeModalRef.current.click();
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getGroups();
    getCategories();
    getPaymentMethods();
    if (selectedGroup?.id) {
      getExpenses();
    }
  }, [refresh, selectedGroup]);

  useEffect(() => {
    if (editExpense) {
      setTitle(editExpense.title);
      setAmount(editExpense.amount);
      setGroup(editExpense.group);
    }
  }, [editExpense]);

  return (
    <div>
      <Header />
      <div className="container pt-3">
        <div className="d-flex gap-3 justify-content-between align-items-center">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Choose Group
            </button>
            <ul className="dropdown-menu">
              {groups.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    setSelectedGroup(item);
                    setTotalExpense(0);
                    setExpenses([]);
                  }}
                >
                  <a className="dropdown-item" href="#">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="btn btn-dark"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#addExpenseModal"
          >
            Add Expense
          </button>
        </div>
        <div className="pt-3">
          <h4>
            Group: <span className="text-primary">{selectedGroup?.name}</span>
          </h4>
        </div>
        <div className="pt-3">
          <h4>
            Total Expense: <span className="text-danger">{totalExpense}</span>
          </h4>
        </div>
        <div className="pt-3">
          <h4>Expenses</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.title}</td>
                  <td>{item.amount}</td>
                  <td>
                    {new Date(item.date.seconds * 1000).toLocaleString(
                      "en-US",
                      {
                        dateStyle: "long",
                        timeStyle: "short",
                      }
                    )}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                      <Edit
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          cursor: "pointer",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#editExpenseModal"
                        className="text-primary"
                        onClick={() => {
                          setEditExpense(item);
                        }}
                      />
                      {/* <Delete
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          cursor: "pointer",
                        }}
                        className="text-danger"
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AddExpense setRefresh={setRefresh} refresh={refresh} groups={groups} />
      <AddGroup setRefresh={setRefresh} refresh={refresh} />
      <AddCategory setRefresh={setRefresh} refresh={refresh} />
      <AddPaymentMethod setRefresh={setRefresh} refresh={refresh} />
      <div
        className="modal fade"
        ref={editExpenseModal}
        id="editExpenseModal"
        tabIndex="-1"
        aria-labelledby="editExpenseModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editExpenseModalLabel">
                Edit Expense
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="amount" className="form-label">
                  Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Group
                </label>
                <select
                  className="form-select"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option defaultValue={""} disabled value={""}>
                    Choose group
                  </option>
                  {groups.map((item) => (
                    <option key={item.id} value={`${item.id}`}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option defaultValue={""} disabled value={""}>
                    Choose Category
                  </option>
                  {categories.map((item) => (
                    <option key={item.id} value={`${item.id}`}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Payment Method
                </label>
                <select
                  className="form-select"
                  value={selectedPaymentMethod}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                >
                  <option defaultValue={""} disabled value={""}>
                    Choose Payment Method
                  </option>
                  {paymentMethods.map((item) => (
                    <option key={item.id} value={`${item.id}`}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                ref={closeModalRef}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => updateExpense(editExpense.id)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
