import {
  addDoc,
  collection,
  getDocs,
  or,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../Firebase";
import useAuth from "../config/hooks/useAuth";

const AddExpense = ({ setRefresh, refresh, groups }) => {
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [group, setGroup] = useState("");
  const [isAnotherDate, setIsAnotherDate] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const closeModalRef = useRef();
  const { user } = useAuth();

  const getCategories = async () => {
    try {
      const q = query(
        collection(db, "category"),
        or(where("user", "==", ""), where("user", "==", user.uid))
      );
      const res = await getDocs(q);
      const data = res.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const q = query(
        collection(db, "paymentMethod"),
        where("user", "==", user.uid)
      );
      const res = await getDocs(q);
      const data = res.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setPaymentMethods(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategories();
    getPaymentMethods();
  }, []);

  console.log("payment methods", paymentMethods);

  const addExpense = async () => {
    try {
      const res = await addDoc(collection(db, "expenses"), {
        title: title,
        amount: parseInt(amount),
        date: isAnotherDate ? new Date(date) : serverTimestamp(),
        group: group,
        user: user.uid,
        category: selectedCategory,
        paymentMethod: selectedPaymentMethod,
      });
      console.log(res);
      closeModalRef.current.click();
      setRefresh(!refresh);
      setAmount("");
      setTitle("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="modal fade"
      id="addExpenseModal"
      tabIndex="-1"
      aria-labelledby="addExpenseModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="addExpenseModalLabel">
              Add Expense
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={isAnotherDate}
                id="isAnotherDate"
                onChange={(e) => setIsAnotherDate(!isAnotherDate)}
              />
              <label className="form-check-label" htmlFor="isAnotherDate">
                Is Another Date?
              </label>
            </div>
            {isAnotherDate && (
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="date"
                  placeholder="Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Group
              </label>
              <select
                className="form-select"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
              >
                <option defaultValue={""} disabled value={""}>
                  Choose group
                </option>
                {groups.map((item) => (
                  <option key={item.id} value={`${item.id}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Category
              </label>
              <select
                className="form-select"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option defaultValue={""} disabled value={""}>
                  Choose Cateogry
                </option>
                {categories.map((item) => (
                  <option key={item.id} value={`${item.id}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Payment Method
              </label>
              <select
                className="form-select"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              >
                <option defaultValue={""} disabled value={""}>
                  Choose Payment Method
                </option>
                {paymentMethods.map((item) => (
                  <option key={item.id} value={`${item.id}`}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              ref={closeModalRef}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => addExpense()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
