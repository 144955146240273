import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../screens/Login";
import Home from "../../screens/Home";
import PrivateRoute from "./PrivateRoute";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default Router;
