import React from "react";
import Logo from "../assets/logo.png";
import GoogleLogo from "../assets/google.png";
import useAuth from "../config/hooks/useAuth";

const Login = () => {
  const { signInWithGoogle } = useAuth();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-primary bg-gradient bg-opacity-25 vh-100 vw-100">
      <div className="d-flex flex-column gap-1 align-items-center">
        <img src={Logo} alt="logo" className="h-25" />
        <h1
          className="text-primary text-center fw-semibold"
          style={{ fontSize: "2.5rem" }}
        >
          Welcome back!
        </h1>
        <p className="fw-normal text-primary fs-4 text-center">
          Sign in to your account to manage your expenses.
        </p>
      </div>
      <div className="">
        <div className="">
          <button
            onClick={() => signInWithGoogle()}
            className="btn btn-light btn-lg d-flex text-primary justify-content-center align-items-center gap-4"
          >
            <img
              src={GoogleLogo}
              alt="google logo"
              style={{
                height: "40px",
                width: "40px",
              }}
            />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
