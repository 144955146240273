import { addDoc, collection } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { db } from "../Firebase";
import useAuth from "../config/hooks/useAuth";

const AddGroup = ({ setRefresh, refresh }) => {
  const [name, setName] = useState("");

  const closeModalRef = useRef();
  const { user } = useAuth();

  const addGroup = async () => {
    try {
      const res = await addDoc(collection(db, "groups"), {
        name: name,
        user: user.uid,
      });
      console.log(res);
      closeModalRef.current.click();
      setRefresh(!refresh);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="modal fade"
      id="addGroupModal"
      tabIndex="-1"
      aria-labelledby="addGroupModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="addGroupModalLabel">
              Add Group
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="groupName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="groupName"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              ref={closeModalRef}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => addGroup()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGroup;
