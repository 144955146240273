import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEhIzyLo4fXwqkw_HO-mnSZ_AEw9xMfBk",
  authDomain: "expense-tracker-alankar.firebaseapp.com",
  projectId: "expense-tracker-alankar",
  storageBucket: "expense-tracker-alankar.appspot.com",
  messagingSenderId: "361880163497",
  appId: "1:361880163497:web:c745aa8c6bf55c634ac5b8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
