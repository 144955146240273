import React from "react";
import useAuth from "../config/hooks/useAuth";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const { user, logout } = useAuth();

  return (
    <nav className="navbar navbar-expand-lg bg-primary">
      <div className="container-fluid">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Link className="navbar-brand text-white" to="/">
            <img src={Logo} alt="Logo" width="40" height="40" class="" />
            Expense Tracker
          </Link>
        </div>
        <div className="dropstart">
          <img
            src={user.photoURL}
            alt="profileImg"
            className="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-display="static"
            style={{ height: "35px", width: "35px", borderRadius: "50%" }}
          />
          <i
            style={{
              border: "solid darkblue",
              borderWidth: "0 3px 3px 0",
              display: "inline-block",
              padding: "3px",
              transform: "rotate(45deg)",
              WebkitTransform: "rotate(45deg)",
              marginLeft: "5px",
            }}
          ></i>
          <ul className="dropdown-menu dropdown-menu-start">
            <li>
              <span
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#addGroupModal"
              >
                Add Group
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#addCategoryModal"
              >
                Add Category
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#addPaymentMethodModal"
              >
                Add Payment method
              </span>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <span className="dropdown-item" onClick={() => logout()}>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
